// external
import React, { useCallback, useMemo } from 'react';
import { Modal } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';

// components
import {
  RelationshipSessionsView,
  RelationshipSessionsCreate,
  RelationshipSessionsEdit,
  RelationshipSessionsIntegrate,
  RelationshipSessionsLog,
  RelationshipSessionHistory,
} from 'components';

// types
import { IRelationship } from '@guider-global/shared-types';

import { getISOStringWithoutMilliseconds } from '@guider-global/front-end-utils';
import { useMixpanelEvents } from 'hooks';

interface IRelationshipSessionsModalProps {
  relationship: IRelationship;
  handleClose: () => void;
  isGroupProgram: boolean;
  isActiveGroup: boolean;
  isGuide: boolean;
}

export const RelationshipSessionsModal: React.FC<
  IRelationshipSessionsModalProps
> = ({ relationship, handleClose, isGroupProgram, isActiveGroup, isGuide }) => {
  const canAccessSessions =
    !relationship.isConcluded &&
    ((isGroupProgram && isActiveGroup && isGuide) || !isGroupProgram);
  const { trackScreenTime } = useMixpanelEvents();
  const { sessionId } = useParams();
  const { pathname } = useLocation();

  const isSessionCreation = useMemo(() => {
    return pathname.includes('/create');
  }, [pathname]);

  const isSessionLog = useMemo(() => {
    return pathname.includes('/log');
  }, [pathname]);

  const isSessionEdit = useMemo(() => {
    return pathname.includes('/edit');
  }, [pathname]);

  const isSessionHistory = useMemo(() => {
    return pathname.includes('/history');
  }, [pathname]);

  const isSessionIntegrate = useMemo(() => {
    return pathname.includes('integrate');
  }, [pathname]);

  const trackSessionCreateFormExit = useCallback(
    () =>
      trackScreenTime(
        'Relationship - Session - Schedule new session - Form exited',
        {
          Started: getISOStringWithoutMilliseconds(),
        },
      ),
    [trackScreenTime],
  );

  const trackSessionCreateFormExitBeforeClose = useCallback(() => {
    trackSessionCreateFormExit();
    handleClose();
  }, [trackSessionCreateFormExit, handleClose]);

  if (isSessionCreation) {
    if (!canAccessSessions) {
      handleClose();
      return <></>;
    }

    return (
      <Modal open onClose={trackSessionCreateFormExitBeforeClose}>
        <RelationshipSessionsCreate
          relationship={relationship}
          handleClose={trackSessionCreateFormExitBeforeClose}
        />
      </Modal>
    );
  }

  const renderContent = () => {
    if (isSessionEdit) {
      if (!canAccessSessions) {
        handleClose();
        return <></>;
      }

      return (
        <RelationshipSessionsEdit
          relationship={relationship}
          handleClose={handleClose}
        />
      );
    }

    if (isSessionLog) {
      if (!canAccessSessions) {
        handleClose();
        return <></>;
      }

      return (
        <RelationshipSessionsLog
          relationship={relationship}
          handleClose={handleClose}
        />
      );
    }

    if (isSessionHistory) {
      return (
        <RelationshipSessionHistory
          relationship={relationship}
          handleClose={handleClose}
          isGroupProgram={isGroupProgram}
          isActiveGroup={isActiveGroup}
          isGuide={isGuide}
        />
      );
    }

    if (isSessionIntegrate) {
      return <RelationshipSessionsIntegrate handleClose={handleClose} />;
    }

    if (sessionId) {
      return (
        <RelationshipSessionsView
          relationship={relationship}
          handleClose={handleClose}
          isGroupProgram={isGroupProgram}
          isActiveGroup={isActiveGroup}
          isGuide={isGuide}
        />
      );
    }

    return <></>;
  };

  return (
    <Modal open onClose={handleClose}>
      {renderContent()}
    </Modal>
  );
};
