import { useContext } from 'react';
import { IconButton } from '@mui/material';
import Close from '@mui/icons-material/Close';
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { RelationshipNotesModalContext } from '../RelationshipNotesModalContext';

interface CloseIconButtonProps {
  onClick: () => void;
}

export function CloseIconButton({ onClick }: CloseIconButtonProps) {
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  const { setOnConfirmDiscardNoteChanges } = useContext(
    RelationshipNotesModalContext,
  );

  const handleClick = (callback: () => void) => {
    setOnConfirmDiscardNoteChanges(() => callback);
  };

  return (
    <IconButton onClick={() => handleClick(onClick)}>
      <Close
        aria-label={baseLanguage.globals?.common?.close_button_label ?? 'Close'}
      />
    </IconButton>
  );
}
